import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import i18n from "../i18next"
import * as styles from "../styles/impressum.module.scss"

const Impressum = () => {
  const isGerman = i18n.language === "de" ? true : false

  return (
    <Layout
      type="impressum"
      isDark={false}
      isMobile={false}
      key={i18n.language}
    >
      <div className="dark">
        {isGerman ? (
          <div className={styles.impressum}>
            <h1>Impressum</h1>
            <h5>Angaben gemäß §5 TMG</h5>
            <h6>Firmenname</h6>
            <p>Vanilla Noir Ltd</p>
            <p>
              Wir sind in England und Wales im Companies House, Crown Way,
              Cardiff, CF14 3UZ unter der Registrierungsnummer 15244694
              eingetragen.
            </p>
            <p>Geschäftsführer: Madeleleine Frister, Maximilian Croissant</p>
            <h6>Adresse</h6>
            <p>
              35 Medallion House
              <br />
              Joseph Terry Grove
              <br />
              YO23 1FL
              <br />
              United Kingdom
            </p>
            <h6>Kontaktinformationen</h6>
            <p>Telefon: +44 7747 206413</p>
            <p>
              E-Mail:{" "}
              <a href="mailto:info@vanilla-noir.com">info@vanilla-noir.com</a>
            </p>
            <h5>Urheberrecht</h5>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
          </div>
        ) : (
          <div className={styles.impressum}>
            <h1>Legal Notice</h1>
            <h5>Legal Information</h5>
            <h6>Company Name</h6>
            <p>Vanilla Noir Ltd</p>
            <p>
              We are registered in England & Wales at Companies House, Crown
              Way, Cardiff, CF14 3UZ under registration number 15244694.
            </p>
            <p>Managing Directors: Madeleleine Frister, Maximilian Croissant</p>
            <h6>Address</h6>
            <p>
              35 Medallion House
              <br />
              Joseph Terry Grove
              <br />
              YO23 1FL
              <br />
              United Kingdom
            </p>
            <h6>Contact</h6>
            <p>Phone: +44 7747 206413</p>
            <p>
              Mail:{" "}
              <a href="mailto:info@vanilla-noir.com">info@vanilla-noir.com</a>
            </p>
            <h5>Copyrights</h5>
            <p>
              The content and works created by the site operators on these pages
              are subject to copyright. The reproduction, editing, distribution
              and any kind of exploitation outside the limits of copyright
              require the written consent of the respective author or creator.
              Downloads and copies of this site are only permitted for private,
              non-commercial use. Insofar as the content on this site was not
              created by the operator, the copyrights of third parties are
              respected. In particular, third-party content is identified as
              such. Should you nevertheless become aware of a copyright
              infringement, please inform us accordingly. If we become aware of
              any infringements, we will remove such content immediately.
            </p>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Impressum

export const Head = () => (
  <Seo
    title="Vanilla Noir | Legal Notice"
    titleDE="Vanilla Noir | Impressum"
    description="Legal information and terms for content of this site"
    descriptionDE="Impressum nach §5 TMG und Urheberrechtserklärung für diese Website"
  />
)
